
export const lightTheme = {
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: "#252525",
    },
    secondary: {
      main: "#9400D3",
    },
    highlighted: {
      main: "#efefef",
      secondary:"#F9FAFB"
    },
    tableRow: {
      info: "#FFFFFF",
      warning: "#FFF4E5",
      error: "#FDEDED",
      success: "#EDF7ED",
      unread: "#F2F6FC",
    },
    text: {
      primary: "#252525",
    },
    background:{
      orders: '#f5f5f5',
      article: '#ffffff',
      page: '#ffffff',
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700, // Custom fontWeight for table headers
        },
      },
    },
  },

}

export const darkTheme = {
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: "#dddddd",
    },
    secondary: {
      main: "#EE82EE",
    },
    highlighted: {
      main: "#181818",
      secondary: "#1c1c1c"
    },
    tableRow: {
      info: "#071318",
      warning: "#191207",
      error: "#160b0b",
      success: "#0C130D",
      unread: "#121212",
    },
    text: {
      primary: "#dddddd",
    },
    background:{
      orders: '#212121',
      article: '#373737',
      page: '#212121',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700, // Custom fontWeight for table headers
          },
        },
      },
    },
    mode: 'dark'
  },
}