export const settingsTrans = {
  settings: "Settings",
  storage: {
    title: "Storage",
    page_title: "Drive integration",
    make_connection: "Connect to the drive",
    remove_connection: "Remove the connection",
    disconnect_success: "The account has been successfully disconnected",
    not_connected: "Your account is not currently connected to any drive",
    connected: "Connected",
    actual_drive: "Currently connected drive",
    drive_name: "Drive name",
    disconnect_title: "Removing the connection to the drive",
    disconnect_confirm: "Yes, disconnect",
    connect_to_drive: "Connect to the drive",
    choose_drive: "Select a drive",
    connect: "Connect",
  },
  api_integration: {
    title: "API integration",
    webhooks: {
      title: "Webhooks",
      not_set: "not set up",
      webhook_not_set: "Currently no URL has been set to send webhooks",
      info: "To receive webhook messages configure API keys",
      set: "Set URL to send webhooks",
      test: "Webhook configuration test",
      response_status: "Response status",
      actual_set: "Currently set Webhook URL",
      tooltip_text: "Make a test HTTP request to the URL indicated in the configuration",
      make_test: "Take a test",
      delete_confirm: "Are you sure you want to delete the webhook?"
    },
    api_key: {
      title: "Your API keys",
      keep_key: "Keep the given key in a safe place",
      never_display_again: "It will not be displayed again",
      public_key: "Public key",
      public_key_copy: "You copied the public key to the clipboard",
      secret_key: "Secret key",
      secret_key_copy: "You copied the secret key to the clipboard",
      deactivate: "Deactivate",
      deactivated: "Deactivated",
      search_via_public_key: "Search by public key",
      show_deactivated: "Show deactivated keys",
      creation_date: "Creation date",
      not_found: "API keys not found",
      deactivate_question: "Are you sure you want to deactivate the api key?",
      deactivate_info: "A deactivated key cannot be reactivated."

    },
  },
  password_change: {
    title: "Password change",
    form: {
      password: {
        error: "The password must contain at least 6 characters",
        label: "Current password",
        caps_lock: "Caps Lock is active",
      },
    },
    button_label: "Send an email with a link to change your password",
  },
  delete_account: {
    title: "Account delete",
    delete_question: "Are you sure you want to delete your SystemAML account?",
    attention: "Warning",
    button_label: "Send an email with a link to delete your account",
  },
  consents: {
    title: "Consents",
    regulations: "Regulations of the AML System service",
    politics: "Privacy policy of the AML System service",
    marketing_consent: "Sending commercial information",
    consents_updated: "User consents have been updated",
    task_notification: "Sending email notification of new task",
    alert_notification: "Sending email notification of new alert",
  }
};
